import { navigate } from "@reach/router";
import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { Controller, Scene } from "react-scrollmagic";
import Slider from "react-slick";
import { InAndUp } from "../shared/transitions";

// import { Link } from '@reach/router';

interface ICaseStudyProps {
  clientName: string;
  title: string;
  clientLogoUrl?: string;
  backgroundImg: string;
  casePageUrl?: string;
}

interface ICaseCarouselProps {
  caseList: ICaseStudyProps[];
  visibleSlides?: number;
}

function CaseCarouselItem(props: ICaseStudyProps) {
  const [animate, setAnimate] = useState(true);
  let timeoutId: NodeJS.Timeout;
  let longClick: boolean = false;
  let meta: { slidePos: Object; logoPos: Object; titlePos: Object } = {};

  const slideRef: MutableRefObject<HTMLDivElement> = useRef();
  const logoRef: MutableRefObject<HTMLDivElement> = useRef();
  const titleRef: MutableRefObject<HTMLDivElement> = useRef();

  const handleMouseDown: Function = e => {
    timeoutId = setTimeout(() => {
      longClick = true;
    }, 200);
  };

  useEffect(() => {
    const slide = slideRef.current;
    const logo = logoRef.current;
    const title = titleRef.current;

    meta.slidePos = slide.getBoundingClientRect();
    meta.logoPos = logo.getBoundingClientRect();
    meta.titlePos = title.getBoundingClientRect();
  });

  const handleMouseUp: Function = e => {
    const isRightClick: boolean = e.button === 0;
    if (!longClick && isRightClick && props.casePageUrl) {
      navigate(props.casePageUrl);

      clearTimeout(timeoutId);
    }
  };

  return (
    <InAndUp
      className="case-carousel__item"
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
    >
      <div
        className="case-carousel__item__background background-image"
        style={{ backgroundImage: `url('${props.backgroundImg}')` }}
        ref={slideRef}
      ></div>
      <div className="client-logo" ref={logoRef}>
        <img src={props.clientLogoUrl} />
      </div>
      <div className="case-carousel__item__inner" ref={titleRef}>
        <small className="client-name">{props.clientName}</small>
        <h5>{props.title}</h5>
      </div>
    </InAndUp>
  );
}

function CaseCarousel(props: ICaseCarouselProps): ReactElement {
  let slider: MutableRefObject<Slider> = useRef(null);
  let [currentSlide, setCurrentSlide] = useState(1);
  let settings = {
    dots: false,
    infinite: true,
    slidesToShow: props.visibleSlides || 3,
    swipeToSlide: true,
    speed: 500,
    arrows: false,
  };

  const renderSliderInfo: Function = () => {
    return slider ? (
      <span>
        {currentSlide}/{props.caseList.length}
      </span>
    ) : null;
  };

  const afterChangeHandler: Function = currentSlide => {
    setCurrentSlide(currentSlide + 1);
  };

  return (
    <>
      <div className="case-carousel__container">
        <Slider
          ref={forwardRef => (slider = forwardRef)}
          {...settings}
          className="case-carousel"
          afterChange={afterChangeHandler}
        >
          {props.caseList.map((props, i) => (
            <CaseCarouselItem {...props} key={i} />
          ))}
        </Slider>
      </div>
      <div className="case-carousel__controls">
        <button
          className="case-carousel__control--prev"
          onClick={() => slider.slickPrev()}
        >
          <FiArrowLeft />
        </button>

        {renderSliderInfo()}

        <button
          className="case-carousel__control--next"
          onClick={() => slider.slickNext()}
        >
          <FiArrowRight />
        </button>
      </div>
    </>
  );
}

export default CaseCarousel;
